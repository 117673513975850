import "core-js/modules/es6.object.assign";
import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Flex from "../../../packages/Flex/src";
import Div from "../../../packages/Div/src";
import Select from "../../../packages/Select/src";
import RatioContainer from "../../../packages/RatioContainer/src";
import Button from "../../../packages/Button/src";
import ButtonGroup from "../../../packages/ButtonGroup/src";

var ControlsBarPattern = function ControlsBarPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Container, {
    size: "sm",
    mt: "5rem"
  }, React.createElement(Flex, {
    alignItems: "center",
    mb: "1.5rem"
  }, React.createElement(Div, {
    mr: "0.75rem",
    style: {
      flexGrow: "1"
    }
  }, React.createElement(Select, {
    width: "100%",
    defaultValue: "1",
    options: [{
      value: "1",
      label: "Option 1"
    }, {
      value: "2",
      label: "Option 2"
    }, {
      value: "3",
      label: "Option 3"
    }]
  })), React.createElement(Div, {
    style: {
      flexGrow: "1"
    }
  }, React.createElement(Select, {
    width: "100%",
    defaultValue: "1",
    options: [{
      value: "1",
      label: "Option 1"
    }, {
      value: "2",
      label: "Option 2"
    }, {
      value: "3",
      label: "Option 3"
    }]
  }))), React.createElement(RatioContainer, {
    ratio: 1 / 2,
    bg: "grey.100"
  }, "Visualisation 4"), React.createElement(Flex, {
    alignItems: "center",
    mt: "1.5rem",
    justifyContent: "center"
  }, React.createElement(Div, {
    width: ["100%", "auto"]
  }, React.createElement(ButtonGroup, {
    onChange: function onChange(btn) {
      return console.log("selected: " + btn.label);
    },
    width: "100%"
  }, function (selected, getItemProps) {
    return React.createElement(React.Fragment, null, [{
      key: 1,
      label: "One"
    }, {
      key: 2,
      label: "Two"
    }, {
      key: 3,
      label: "Three"
    }].map(function (item) {
      var isSelected = item.key === selected.key;
      return React.createElement(Button, Object.assign({
        key: item.key,
        variant: isSelected ? "primary" : "default"
      }, getItemProps(item), {
        style: {
          flexGrow: "1"
        }
      }), item.label);
    }));
  })))));
};

export default ControlsBarPattern;